import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { graphql, Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../generic-page.scss';
import './tier-list.scss';
import { Accordion, Button, Card } from 'react-bootstrap';
import {
  faAnglesDown,
  faArrowDown,
  faArrowUp,
  faAsterisk,
  faBriefcaseMedical,
  faFlask,
  faHandFist,
  faStar,
  faVirus
} from '@fortawesome/free-solid-svg-icons';
import {
  faFaceSadTear,
  faStar as faStarEmpty
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IHSRCharacter } from '../../modules/common/model/graphql-types';
import lodash from 'lodash';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { HSRCharacter } from '../../modules/hsr/common/components/hsr-character';
import Switch from 'react-switch';
import { CustomPopover } from '../../modules/cs/common/components/custom-popover';

interface IHSRCharacterNodes {
  nodes: IHSRCharacter[];
}

interface IHSRCharacterEntry {
  allCharacters: IHSRCharacterNodes;
}

interface IProps {
  data: IHSRCharacterEntry;
}

const HSRTierListPage: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [eidolonSpecial, setEidolonSpecial] = useState(true);
  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: '4★', value: '4' },
          { label: '5★', value: '5' }
        ]
      },
      {
        key: 'element',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Physical',
            tooltip: 'Physical',
            image: (
              <StaticImage
                src="../../images/starrail/icons/ele_physical.png"
                width={24}
                alt="Physical"
              />
            )
          },
          {
            value: 'Fire',
            tooltip: 'Fire',
            image: (
              <StaticImage
                src="../../images/starrail/icons/ele_fire.png"
                width={24}
                alt="Fire"
              />
            )
          },
          {
            value: 'Ice',
            tooltip: 'Ice',
            image: (
              <StaticImage
                src="../../images/starrail/icons/ele_ice.png"
                width={24}
                alt="Ice"
              />
            )
          },
          {
            value: 'Lightning',
            tooltip: 'Lightning',
            image: (
              <StaticImage
                src="../../images/starrail/icons/ele_lightning.png"
                width={24}
                alt="Lightning"
              />
            )
          },
          {
            value: 'Wind',
            tooltip: 'Wind',
            image: (
              <StaticImage
                src="../../images/starrail/icons/ele_wind.png"
                width={24}
                alt="Wind"
              />
            )
          },
          {
            value: 'Quantum',
            tooltip: 'Quantum',
            image: (
              <StaticImage
                src="../../images/starrail/icons/ele_quantum.png"
                width={24}
                alt="Quantum"
              />
            )
          },
          {
            value: 'Imaginary',
            tooltip: 'Imaginary',
            image: (
              <StaticImage
                src="../../images/starrail/icons/ele_imaginary.png"
                width={24}
                alt="Imaginary"
              />
            )
          }
        ]
      },
      {
        key: 'path',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Abundance',
            tooltip: 'Abundance',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_abundance.png"
                width={24}
                alt="Abundance"
              />
            )
          },
          {
            value: 'Destruction',
            tooltip: 'Destruction',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_destruction.png"
                width={24}
                alt="Destruction"
              />
            )
          },
          {
            value: 'Erudition',
            tooltip: 'Erudition',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_erudition.png"
                width={24}
                alt="Erudition"
              />
            )
          },
          {
            value: 'Harmony',
            tooltip: 'Harmony',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_harmony.png"
                width={24}
                alt="Harmony"
              />
            )
          },
          {
            value: 'Hunt',
            tooltip: 'Hunt',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_hunt.png"
                width={24}
                alt="Hunt"
              />
            )
          },
          {
            value: 'Nihility',
            tooltip: 'Nihility',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_nihility.png"
                width={24}
                alt="Nihility"
              />
            )
          },
          {
            value: 'Preservation',
            tooltip: 'Preservation',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_preservation.png"
                width={24}
                alt="Preservation"
              />
            )
          },
          {
            value: 'Remembrance',
            tooltip: 'Remembrance',
            image: (
              <StaticImage
                src="../../images/starrail/icons/path_remem.png"
                width={24}
                alt="Remembrance"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (sortOption === 'moc' && eidolonSpecial === false) {
      setSortOption('moc_special');
    }

    if (sortOption === 'moc_special' && eidolonSpecial === true) {
      setSortOption('moc');
    }

    if (sortOption === 'pure' && eidolonSpecial === false) {
      setSortOption('pure_special');
    }

    if (sortOption === 'pure_special' && eidolonSpecial === true) {
      setSortOption('pure');
    }

    if (sortOption === 'apo' && eidolonSpecial === false) {
      setSortOption('apo_special');
    }

    if (sortOption === 'apo_special' && eidolonSpecial === true) {
      setSortOption('apo');
    }

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.rarity.indexOf(activeFilters.rarity) > -1
        );
      }
      if (activeFilters.element && activeFilters.element !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.element.toLowerCase() === activeFilters.element.toLowerCase()
        );
      }
      if (activeFilters.path && activeFilters.path !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.path.toLowerCase() === activeFilters.path.toLowerCase()
        );
      }
    }

    if (sortOption) {
      filtered = filtered.sort((a, b) =>
        a[sortOption] < b[sortOption] ? 1 : -1
      );
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption, eidolonSpecial]);

  const sortingOptions = [
    { value: 'moc', label: 'Memory of Chaos' },
    { value: 'pure', label: 'Pure Fiction' },
    { value: 'apo', label: 'Apocalyptic Shadow' }
  ];

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    // eslint-disable-next-line prefer-destructuring
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier list</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/starrail/categories/category_tierlist.png"
            alt="Tier list"
          />
        </div>
        <div className="page-details">
          <h1>Honkai: Star Rail Tier list (February 2025)</h1>
          <h2>
            The most popular tier list for Honkai: Star Rail that rates all
            available characters by their performance in Memory of Chaos, Pure
            Fiction and Apocalyptic Shadow.
          </h2>
          <p>
            Last updated: <strong>05/02/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
      {/* <div className="afk-ad-banner">
        <div className="cta">
          <h4>New Season "Waves of Intrigue" is now live in AFK Journey</h4>
          <p>Unveil the mysteries of Rustport and uncover new adventures!</p>
          <OutboundLink href="https://bit.ly/PrydwenHSR" target="_blank">
            <Button variant="primary">Download Now</Button>
          </OutboundLink>
        </div>
      </div> */}
      <div className="tier-list-page">
        <SectionHeader title="Honkai: Star Rail Tier list" />
        <Accordion defaultActiveKey="0" className="tier-list-accordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header>About the Tier List</Accordion.Header>
            <Accordion.Body>
              <p>
                Please keep in mind that Honkai: Star Rail{' '}
                <strong>is a game where team building matters most</strong> and
                while our tier list takes the optimal setup into account, a lot
                of characters can work and do well - even those ranked lower -
                when you invest into them. Also for story or lower difficulties
                of Simulated Universe, you don't need to worry about ratings and
                tiers. You can safely clear that content even with F2P
                characters as long as you build your team correctly.
              </p>
              <p>
                These tier lists rate characters based on their average
                performance in{' '}
                <strong>
                  Memory of Chaos, Pure Fiction and Apocalyptic Shadow
                  regardless of turbulence, whimsicality and cacophony
                </strong>{' '}
                <strong className="red">(last 3 phases specifically)</strong>.
                Characters rated higher will perform well without the need to
                rely on these and will only benefit more from receiving them. As
                new mechanics, characters and challenges are introduced each
                tier list will be updated.
              </p>
              <p>
                <strong>
                  Important! Characters are ordered alphabetically within a tier
                </strong>
                .
              </p>
              <p>Available tier lists:</p>
              <ul>
                <li>
                  <strong>Memory of Chaos (MoC)</strong> - how the character
                  performs in the Memory of Chaos. Blast and single target
                  damage are important here while AoE has niche uses against
                  some bosses.
                </li>
                <li>
                  <strong>Pure Fiction (PF)</strong> - how the character
                  performs in the Pure Fiction. AoE is king here, followed by
                  Blast while single target damage is mostly useless.
                </li>
                <li>
                  <strong>Apocalyptic Shadow (AS)</strong> - how the character
                  performs in the Apocalyptic Shadow. Single target and Break
                  potential are heavily favored here.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Categories & Tags</Accordion.Header>
            <Accordion.Body>
              <h6>Categories</h6>
              <ul className="hsr-categories">
                <li>
                  <strong className="dps">DPS</strong> - Characters that mainly
                  focus on dealing damage and being the main source of it in the
                  team they are placed in,
                </li>
                <li>
                  <strong className="specialist">Support DPS</strong> -
                  Characters that fall within this category are very rarely
                  played as the primary or sole damage dealer of the team and
                  instead almost always play alongside another character that
                  fills that role instead. Support DPS are still built with the
                  intention to deal damage themselves but also have a kit
                  containing buffs/debuffs to bolster their team's performance
                  and/or have abilities that are directly dependent on others in
                  order to function.
                </li>
                <li>
                  <strong className="amplifier">Amplifier</strong> - Characters
                  that do not focus on dealing damage but instead amplify the
                  damage other teammates deal by granting allies buffs or
                  debuffing enemies,
                </li>
                <li>
                  <strong className="sustain">Sustain</strong> - Characters who
                  are capable of keeping the team alive with their abilities.
                </li>
              </ul>
              <h6>Special tags</h6>
              <p>
                Tags are split into 3 categories:{' '}
                <strong className="positive">Pros</strong>,{' '}
                <strong className="negative">Cons</strong> and{' '}
                <strong className="archetype">Archetype</strong> which represent
                the most defining features of a character's kit and the current
                meta archetypes they fit into. Archetype tags indicate a
                connection between characters with that tag e.g. Acheron
                requires debuffs to work and Silver Wolf applies them. Here is a
                full list of all current tags and what they mean:
              </p>
              <ul>
                <li>
                  <strong className="archetype">Break</strong> - the character
                  belongs to the Break archetype focusing on Break Effect and
                  Break Efficiency,
                </li>
                <li>
                  <strong className="archetype">Debuff</strong> - the character
                  belongs to the Debuff archetype applying one or many debuffs
                  or has a kit that directly relies on them to function,
                </li>
                <li>
                  <strong className="archetype">DoT</strong> - the character
                  belongs to the Damage-over-Time archetype specializing in
                  applying and/or amplifying DoT effects,
                </li>
                <li>
                  <strong className="archetype">FuA</strong> - the character
                  belongs to the Follow-up Attack archetype benefitting from,
                  buffing or delivering powerful Follow-Up Attacks.,
                </li>
                <li>
                  <strong className="archetype">Summon</strong> - the character
                  fights alongside summons or other entities,
                </li>
                <li>
                  <strong className="positive">Advance</strong> - the character
                  can manipulate the turn order by either action advancing their
                  allies or themselves,
                </li>
                <li>
                  <strong className="positive">Buff</strong> - the character can
                  apply various Buffs to help their teammates,
                </li>
                <li>
                  <strong className="positive">Delay</strong> - the character
                  can delay enemies and hinder their actions in battle,
                </li>
                <li>
                  <strong className="positive">Energy</strong> - the character
                  can recover the teammates energy,
                </li>
                <li>
                  <strong className="positive">SP-Friendly</strong> - the
                  character is substantially more skill point friendly than
                  other characters in their role.
                </li>
                <li>
                  <strong className="negative">SP-Unfriendly</strong> - the
                  character is substantially more skill point un-friendly than
                  other characters in their role.
                </li>
                <li>
                  <strong className="negative">Partner</strong>{' '}
                  <span className="tag new">New!</span> - the character is
                  incredibly reliant on being partnered with one specific
                  character in order to function at the highest level.
                  Characters marked with this tag will perform one (but
                  sometimes even more) tiers lower than their listed position on
                  the tier list without those characters alongside them.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Ratings & Meta Lines</Accordion.Header>
            <Accordion.Body>
              <h5>Meta Lines</h5>
              <p>
                Meta Lines categorize each section of the tier list into a power
                bracket. Here are the four brackets:
              </p>
              <ul>
                <li>
                  <strong className="tier-05">
                    Apex Characters (T0 & T0.5)
                  </strong>{' '}
                  - characters in this bracket are the cream of the crop. They
                  can do outrageous amount of damage, provide massive buffs (or
                  debuffs) or keep the team alive no matter what the enemy
                  throws at them. They simply can achieve the best, most
                  consistent and accessible results in their best teams,
                  allowing you to easily clear the content,
                </li>
                <li>
                  <strong className="tier-2">
                    Meta Characters (T1, T1.5, T2)
                  </strong>{' '}
                  - characters in this bracket make clearing the content a
                  breeze, but compared to the Apex characters they require more
                  work from your side. Either their team options are more
                  restrictive, they require bigger Relic investment or they
                  possess a notable weakness or downside. Still, they are all
                  great and won't disappoint you if you give them what they need
                  to shine,
                </li>
                <li>
                  <strong className="tier-4">Niche Characters (T3 & T4)</strong>{' '}
                  - characters in this bracket are lacking in one or many fields
                  of their role. These characters still possess the ability to
                  clear all content with the right build, endgame blessing, team
                  or enemy line-up but have much lower play rates and success
                  rates than Meta and Apex characters,
                </li>
                <li>
                  <strong className="tier-5">The Forgotten Ones (T5)</strong> -
                  characters in this bracket struggle. A lot. Either their kits
                  simply don't work in the mode or they require a ton of
                  investment and love to be able to work. Just avoid them unless
                  your husbando or waifu landed here and you believe in the
                  power of love.
                </li>
              </ul>
              <h5>Half tiers</h5>
              <p>
                To further define the tier list and correctly represent the
                power level of each character in relation to each other we have
                added half tiers to the higher tier brackets. Under our previous
                system a tier rating jump of 1 tier rating made it difficult to
                position characters accurately, often leading to multiple
                characters ending up in the same tier despite a clear power and
                utility difference.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Criteria</Accordion.Header>
            <Accordion.Body>
              <h6>Criteria</h6>
              <ul>
                <li>Manual play,</li>
                <li>Using optimal team composition/support characters,</li>
                <li>Character level 80,</li>
                <li>
                  All Major Traces unlocked and skills upgraded to level 10,
                </li>
                <li>
                  Character equipped with level 80{' '}
                  <strong>non-limited 4★ or 5★ Light Cone</strong> (including
                  the 'standard' Light Cones),
                </li>
                <ul>
                  <li>
                    Battle Pass Light Cones aren't taken into account because
                    you can't obtain them without spending,
                  </li>
                </ul>
                <li>
                  Character equipped with 5★ upgraded gear with optimal Main
                  Stats and Sub Stats (with 24 desired sub stats out of a
                  possible 54),
                </li>
                <li>Eidolon level 0 for 5★ characters,</li>
                <li>Eidolon level 0/6 for 4★ characters.</li>
              </ul>
              <h6>Roles</h6>
              <p>
                We compare characters within their role - so DPS are compared to
                other DPS and the ratings are adjusted based on their
                performance within the role. Don't try to compare characters
                between roles (for example an Amplifier with a DPS), because
                their ratings mean different things.
              </p>
              <ul className="hsr-categories">
                <li>
                  <strong className="dps">DPS</strong> - Rating is heavily
                  influenced based on the characters ability to deal enough
                  damage to achieve the 3-star objective, the easier and more
                  quickly achieved the higher the rating,
                </li>
                <li>
                  <strong className="specialist">Support DPS</strong> - Rating
                  is heavily influenced based on the damage they provide, the
                  buffs and debuffs or efficiency boost these characters create
                  or the utility they provide against certain encounters,
                </li>
                <li>
                  <strong className="amplifier">Amplifier</strong> - Rating is
                  influenced based on the damage amplification abilities of the
                  character and the utility they provide to the team,
                </li>
                <li>
                  <strong className="sustain">Sustain</strong> - Rating is
                  influenced based on the characters ability to keep the team
                  alive and the utility they provide. The more utility and
                  higher the safety, the higher the rating.
                </li>
              </ul>
              <h6>Other criteria affecting the ratings</h6>
              <p>
                Each tier list rates characters based on their average value in
                the specific game mode it represents.{' '}
                <strong>
                  The higher a character is rated on the tier list the greater
                  their power and more widely they can be used effectively in
                  the mode
                </strong>
                , helping the players in getting all rewards every cycle.{' '}
                <strong className="red">
                  We aren't looking at 0-cycle potential
                </strong>
                . We consider:
              </p>
              <ul>
                <li>
                  <strong>Flexibility</strong> - how the characters kit
                  functions against various enemy types, amounts of enemies and
                  their ability to perform when not fighting against enemies
                  vulnerable to their element,
                </li>
                <li>
                  <strong>Investment</strong> - some characters require specific
                  limited 5★ units, heavy investment in certain relic sets or an
                  abundance of sub-stats in order to function at the highest
                  level. Each of these points impacts the rating in a negative
                  way. - the more the character requires to perform well, the
                  bigger the impact,
                </li>
                <li>
                  <strong>Utility</strong> - recently released characters
                  possess abilities that lie beyond their defined role, for
                  example Amplifiers/Sustains that can deal decent damage. The
                  more a character helps the team outside of their role, the
                  bigger positive impact on their rating it has.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              Changelog
              {/* <span className="tag new">Updated!</span> */}
            </Accordion.Header>
            <Accordion.Body>
              <h6>
                <strong>05/02/2025</strong>
              </h6>
              <p>
                With the release of{' '}
                <HSRCharacter mode="inline" slug="aglaea" enablePopover /> we’re
                adding a new tag - the Partner Tag - placing Aglaea on the tier
                list, updating Sunday’s ratings and addressing the current state
                of flux the endgame has found itself in post 3.0 world.
              </p>
              <p>
                As we’ve flagged in our previous updates, all three modes are
                undergoing a shift in meta toward a more AoE focus (well, PF was
                already AoE-focused, but now it's AoE-focus+). We’re fully aware
                that some characters are rated too high - specifically
                characters with a single target focus or who don’t match up well
                against new enemy mechanics or vulnerabilities. With that said,
                we want to avoid repositioning a character incorrectly. To
                ensure this doesn’t happen, we’re waiting for the next phase of
                Pure Fiction (currently underway) and Memory of Chaos (coming
                soon) to make major changes.
              </p>
              <p>
                That's why we have only added Aglaea to the list and adjusted
                the position of a single character, majorly affected by her
                release:
              </p>
              <ul>
                <li>
                  <HSRCharacter mode="inline" slug="aglaea" enablePopover /> -
                  Under the right conditions, in the right team, with the
                  correct build and Speed-tuning, Aglaea can be - and is - one
                  of the strongest damage dealers in the game when it comes to
                  raw numbers, making her quite a threat specifically in her
                  best endgame mode - Memory of Chaos. With that said, similar
                  to past character releases, we’ve decided to commit to a
                  conservative placement for her initially. We’re confident
                  Aglaea has what it takes to achieve a T0 ranking under ideal
                  conditions but we want to see how she’ll perform in the hands
                  of the community, with a variety of different investment,
                  relic quality, team and tuning levels. Like with The Herta,
                  you can expect an update on the state of Aglaea shortly after
                  her release.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="sunday" enablePopover /> -
                  With Aglaea, Sunday gains yet another top tier dps partner,
                  specifically in MoC. As a character that is mandatory for some
                  and a top choice for most others in MoC, we’re moving him up
                  to Tier 0.
                </li>
              </ul>
              <p>And here's the definition of the new tag:</p>
              <p>
                <strong>Partner</strong> - characters with the Partner tag are
                incredibly reliant on being paired with one other specific
                character in order to function at the highest level. Characters
                marked with this tag will perform one - but sometimes even more
                tiers - lower than their listed position on the tier list
                without those characters alongside them.
              </p>
              <p>
                We’re adding this tag to address and flag the weakness some
                characters have when played without their ideal “partner”.
                Characters we currently feel that fall under this category are:
              </p>
              <ul>
                <li>
                  <HSRCharacter mode="inline" slug="acheron" enablePopover />{' '}
                  who relies on{' '}
                  <HSRCharacter mode="inline" slug="jiaoqiu" enablePopover />
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="aglaea" enablePopover /> who
                  relies on{' '}
                  <HSRCharacter mode="inline" slug="sunday" enablePopover />
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="jing-yuan" enablePopover />{' '}
                  who also relies on{' '}
                  <HSRCharacter mode="inline" slug="sunday" enablePopover />
                </li>
              </ul>
              <p>
                Let us know how you feel about the Partner tag and if you think
                additional characters should be tagged with it (just remember,
                for the Partner tag to apply, the next best alternative causes
                the character to drop a rank or more when it comes to their
                performance).
              </p>
              <h6>
                <strong>25/01/2025</strong>
              </h6>
              <p>
                The dust after the 3.0 release has settled and with it came the
                release of a new phase of Apocalyptic Shadow. In this update
                we’re addressing the feedback to our major rework to all tier
                lists, the release of The Herta and Trailblazer Remembrance, and
                the meta shifts caused by Apocalyptic Shadow changes to be more
                AoE-focused.
              </p>
              <p>
                <HSRCharacter mode="inline" slug="the-herta" enablePopover />{' '}
                (and most Erudition characters that work with her) and{' '}
                <HSRCharacter
                  mode="inline"
                  slug="trailblazer-remembrance"
                  enablePopover
                />{' '}
                have all exceeded their initial more conservative rankings on
                the tier list. As a result{' '}
                <HSRCharacter mode="inline" slug="serval" enablePopover />{' '}
                <HSRCharacter mode="inline" slug="herta" enablePopover />{' '}
                <HSRCharacter mode="inline" slug="jade" enablePopover />{' '}
                <HSRCharacter mode="inline" slug="argenti" enablePopover />{' '}
                <HSRCharacter
                  mode="inline"
                  slug="trailblazer-remembrance"
                  enablePopover
                />{' '}
                improved their ratings.
              </p>
              <p>
                Based on feedback we’re also making adjustments to the ranking
                of Support DPS in the Follow-Up ATK archetype to better convey
                the difference in power, usability and flexibility between{' '}
                <HSRCharacter mode="inline" slug="topaz" enablePopover />{' '}
                <HSRCharacter
                  mode="inline"
                  slug="march-7th-swordmaster"
                  enablePopover
                />{' '}
                and <HSRCharacter mode="inline" slug="moze" enablePopover />.
                The difference between Topaz and March 7th Hunt isn’t large
                enough to maintain a full tier difference between the two -
                especially when that Tier is Tier 0 - whilst Moze is more
                situational than both of them.
              </p>
              <p>
                Apocalyptic Shadow includes changes caused by The Herta's
                release and the change to the mode itself that become apparent
                in the new mode - AOE-focus (or rather, AoE-shilling). As
                mentioned in our previous update, the meta is undergoing a large
                shift towards heavy AoE Focus (5 targets) - and AS is the first
                to receive the brunt of this shift. The result of this is a
                decline in the viability of Single Target focused characters
                with an improvement for AoE Focused characters. We’re being
                conservative in our changes for now, to see how players, teams
                and encounters evolve in future phases but here are the AS
                specific changes being made for now.
              </p>
              <ul>
                <li>
                  <HSRCharacter mode="inline" slug="boothill" enablePopover />{' '}
                  T0{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T0.5. Boothill, a man who specialises in single target
                  Toughness bar destruction, is at a disadvantage when it comes
                  to the new AoE-focused encounters in the latest Phases of AS.
                  It should come as no surprise he can’t quite duke it out with
                  characters with kits designed specifically to counter the
                  bosses we’re up against. He’s moving down a tier for now but
                  will likely be back when Single Target returns.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="dr-ratio" enablePopover />{' '}
                  T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1.5. Like Boothill, Dr. Ratio is similarly struggling with
                  being single target and reliant on debuffs to function.
                  Keeping constantly respawning enemies debuffed enough for
                  Ratio to trigger his Follow-Up attacks, on top of having to
                  deal with them one by one is a huge penalty compared to his
                  competition. Ratio is struggling to keep pace with his peers,
                  we’re lowering him a tier for now.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="argenti" enablePopover /> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1.5. The AoE changes, introduction of The Herta and a new
                  favorable AS boss have all been huge for Argenti and it shows.
                  We’re raising Argenti up a tier as a result of this.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="the-herta" enablePopover />{' '}
                  (Watchlist): The Herta has produced smashing results in the
                  Apocalyptic Shadow with a boss which for all intents and
                  purposes was designed for her by achieving some of the best
                  scores. While her performance this phase is undoubtedly Tier 0
                  worthy we want to see how she performs against a boss not so
                  specifically tailored toward her strengths. As a result we’re
                  watchlisting her for now.
                </li>
              </ul>
              <p>Big thanks for all the feedback provided!</p>
              <h6>
                <strong>15/01/2025</strong>
              </h6>
              <p>
                With the release of the 3.0 update we’re implementing a change
                we’ve been intending on making, and which has been much
                requested for many months now - the shift to having all primary
                damage dealers be rated in the same category.
              </p>
              <p>
                This entails moving all characters we feel capable of serving as
                a “Main Damage Dealer” to the DPS Category, most of which are
                shifting from the Specialist Category. Here are the character’s
                moving:
              </p>
              <ul>
                <li>
                  <HSRCharacter mode="inline" slug="boothill" enablePopover />
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="firefly" enablePopover />
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="rappa" enablePopover />
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="sushang" enablePopover />
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="xueyi" enablePopover />
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="luka" enablePopover />
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="kafka" enablePopover />
                </li>
              </ul>
              <p>
                With the exception of Luka and Kafka (we’ll come back to them
                soon) we feel each of these characters are far more accurately
                represented in their new home.
              </p>
              <p>
                In addition to the re-ordering above, we’re also renaming the
                Specialist Category to something else. The Specialist Category
                was initially intended as a home for all characters that didn’t
                fit the traditional damage mold. It was the solution to
                characters that didn’t just deal raw damage but also offered
                something else. It was the home of Breakers, Hybrid
                Support-Damage dealers and highly synergistic characters that
                didn’t function well alone. Over time these characters, and
                mechanics have evolved, became more accessible and all operate
                more closely to traditional damage dealers.
              </p>
              <p>
                All this led to the decision to not only recategorize some
                characters but also repurpose the Specialist category into
                something more relevant toward how the game and meta plays
                today. We’re repurposing the Specialist Category into the
                Support DPS Category.
              </p>
              <ul>
                <li>
                  <strong>Support DPS</strong>: Characters that fall within this
                  category are very rarely played as the primary or sole damage
                  dealer of the team and instead almost always play alongside
                  another character that fills that role instead. Support damage
                  dealers are still built with the intention to deal damage
                  themselves but also have a kit containing buffs/debuffs to
                  bolster their team's performance and or have abilities that
                  are directly dependent on others in order to function.
                </li>
              </ul>
              <p>
                As the meta has developed we’ve seen an increase in characters
                that are half way between damage and support as well as
                characters that can deal good amounts of damage but do so in a
                far more supportive role.
              </p>
              <p>
                With the release of The Herta we’re moving the category of two
                erudition characters to better reflect their role in the current
                meta as Support DPS:
              </p>
              <ul>
                <li>
                  <HSRCharacter mode="inline" slug="herta" enablePopover /> or
                  “Mini Herta” is already played primarily as a “Sub-DPS” in
                  modes such as Pure Fiction and Apocalyptic shadow, serving as
                  an incredible partner to almost all other characters, thanks
                  to her Follow-Up spins which she relies on her teammates to
                  help trigger. With the release of The Herta, she gains even
                  more dual DPS synergy as she can satisfy the Erudition
                  requirement The Herta seeks to fulfill, giving her Support DPS
                  potential even in modes she wasn’t great in previously like
                  MoC.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="serval" enablePopover /> -
                  Prior to The Herta’s release, Serval hasn’t seen much play or
                  meta relevance but now she can operate as one of the better
                  Erudition supports for The Herta. Playing Serval normally is
                  good enough but she can also double down on a far more
                  supportive build involving maximum energy regeneration
                  solidifying her for us as a Support DPS for now.
                </li>
              </ul>
              <p>
                As for{' '}
                <HSRCharacter mode="inline" slug="lingsha" enablePopover />{' '}
                she's a Healer that loves killing and she honestly could be
                placed in Support DPS too. Still, she's a Healer first so we
                decided to don't do it, yet. One option we're considering is
                placing Lingsha in two categories at the same time and rate her
                by different criteria - let us know what you think about this
                idea.{' '}
              </p>
              <p>
                Finally coming back to{' '}
                <HSRCharacter mode="inline" slug="luka" enablePopover /> and{' '}
                <HSRCharacter mode="inline" slug="kafka" enablePopover />. These
                characters are two we feel could belong in both categories. It
                could be argued that Kafka only truly functions with another DoT
                character on the team, due to her DoT triggering nature - it can
                also be argued that Luka is more effective when played alongside
                another damage dealer, serving as a break finisher instead of
                the sole contributor. Right now we’re moving forward with both
                as Main DPS - we feel Kafka is usually the driving force of DoT
                teams she’s placed in and therefore is the main contributor and
                in a similar boat when Luka is played he’s usually the one
                outputting a large portion of the teams damage thanks to his
                giant Physical Break damage.
              </p>
              <p>
                With so many changes you can treat this update as a rework to
                the tier list. Also, we expect a large meta shake-up over the
                course of 3.x,{' '}
                <strong>
                  especially because all modes started morphing into being more
                  AoE-focused
                </strong>{' '}
                - this trend started with Rappa release and got even bigger with
                The Herta. We will have to wait and see how long it will last,
                but at least for now, single-target focused characters are at a
                big disadvantage (with the exception of Feixiao). And as always
                we’re always listening to feedback so let us know what you
                think.
              </p>
              <h6>
                <strong>05/01/2025</strong>
              </h6>
              <p>
                This tier list update will be quite unique due a few reasons.
                First one is the revamp of the Pure Fiction mode and the second
                is the upcoming 3.0 full tier list revamp. We will go over both
                in the changelog.
              </p>
              <p>
                <strong>Pure Fiction rework</strong>
              </p>
              <p>
                Pure Fiction has received a rework. The changes to the mode
                include:
              </p>
              <ul>
                <li>
                  The introduction of the Grit mechanic, replacing the old buff
                  mechanic of Pure Fiction (buffs are more generic in nature so
                  far),
                </li>
                <li>
                  Constantly respawning non-boss enemies, resulting in the field
                  always having 5 enemies at a time (much heavier AoE focus),
                </li>
                <li>
                  Boss enemies now always take damage when any non-elite enemy
                  dies (even more focus on AoE).
                </li>
              </ul>
              <p>
                After testing and comparing our results against the first set of
                data under PF’s new rules, here is the general theme of the
                changes to the meta and tier list:
              </p>
              <ul>
                <li>
                  Characters capable of hitting 3-5 enemies frequently are more
                  powerful while characters with more singular focused damage
                  are weaker,
                </li>
                <li>
                  Characters with trigger on kill or conditional effects that
                  scale on number of enemies in the fight are stronger,
                </li>
                <li>
                  Supports, sustains and specialists that play into the kits of
                  any of the characters included in the categories above are
                  stronger.
                </li>
              </ul>
              <p>
                Before you look at the updated PF ratings, here are a few things
                to note:
              </p>
              <ul>
                <li>
                  Only one phase is currently available, one set of Grit buffs
                  and one set of enemies, limiting the amount of testing and
                  data that can be collected,
                </li>
                <li>
                  Compared to pre-revamp version of the mode, this current phase
                  is one of the easiest ever - both our testing and the data
                  show that on average, most characters can reach way higher
                  scores than before,
                </li>
                <li>
                  Erudition is heavily favored in this mode even more so than
                  before. The Herta is a new limited Erudition unit releasing in
                  3.0 and she looks to be very powerful - it’s likely she will
                  shake up the DPS category,
                </li>
                <li>
                  Apex Amplifier’s are in an unusual position right now. All of
                  Sunday, Fugue, Robin, Jiaoqiu and Ruan Mei have strong
                  arguments for being T0 - they each have a team that has shown
                  it can obliterate the reworked game mode and can work
                  independently of each other. While we feel some do have an
                  edge over others we’re going to wait for the release of The
                  Herta, another phase of Pure Fiction and more data before
                  committing to placing someone in T0.
                </li>
              </ul>
              <p>
                Because of all the above, there won't be a detailed changelog
                this time - it's more like a blank slate that is work in
                progress due to all the limitations and with the next phase we
                will know more about the direction Hoyo wants to take the mode
                in.
              </p>
              <p>
                <strong>Upcoming 3.0 Tier list revamp</strong>
              </p>
              <p>
                We already mentioned the revamp several times, but here's some
                more information about what we plan to change:
              </p>
              <ul>
                <li>
                  The current DPS and Specialist categories will be killed,
                </li>
                <ul>
                  <li>
                    Specialists category is a relic from the early days of HSR
                    where Break and other non-Crit archetypes were less popular
                    and we created it to distinct them from each other. But
                    nowadays, CRIT and non-CRIT DPS just fill the same role -
                    killing things. So it makes no sense to keep them split,
                  </li>
                </ul>
                <li>
                  A new category will be created - <strong>Main DPS</strong>.
                  Damage dealers of all archetypes - CRIT, Break, DoT, FUA -
                  will all be placed there. As the name suggests, those
                  characters are primarily used as the main damage source on the
                  team, no matter their archetype,
                </li>
                <li>
                  A new category will be created - <strong>Hybrid</strong>.
                  Hybrid categories are characters that on top of doing damage,
                  have some supportive abilities that help the team - either via
                  buffs or debuffs. What is important, they can do both decent
                  damage and support at the same time, without sacrificing
                  either. Pure supporters whose damage is negligible will still
                  remain in the Amplifier category. Here are a few examples of
                  characters who will end up in this category: Jade, Topaz,
                  Moze, and Black Swan (maybe even Lingsha, but she would be an
                  exception appearing in two categories at the same time).
                </li>
              </ul>
              <p>
                There will be A LOT of changes coming with that update,
                especially in the APEX category as we will have to change the
                criteria and pit the CRIT archetype against all the others.
                That's why we didn't make any drastic changes in the current
                Pure Fiction update (4x T0 DPS characters for example) as it's
                the last time things look like that.
              </p>
              <p>
                <strong>Other post-Fugue changes</strong>
              </p>
              <p>
                <HSRCharacter
                  mode="inline"
                  slug="tingyun-fugue"
                  enablePopover
                />{' '}
                has proven to be way stronger than we anticipated, so we have
                lowered Trailblazer Harmony ratings in MoC and AS to better
                showcase the pecking order when it comes to Super Break
                supports.
              </p>
              <h6>
                <strong>25/12/2024</strong>
              </h6>
              <p>
                In this update we’ll be adding{' '}
                <HSRCharacter
                  mode="inline"
                  slug="tingyun-fugue"
                  enablePopover
                />{' '}
                to the tier list, making changes as a result of her release and
                making updates to Apocalyptic Shadow ratings.
              </p>
              <p>
                Fugue like Sunday is a character with raw power, huge potential
                and brand new mechanics which all combined make placing her
                perfectly a challenge. Based on this, don't be surprised if
                after the community gets their hands on her, more players test
                her, discover synergies and we get solid data on her performance
                in each mode she sees improvements to her ratings.
              </p>
              <p>
                Also as a part of Fugue’s release we’ve taken a look at
                characters she impacts most and adjusted their rating in some
                modes. Some changes we’ve made immediately - others we’re
                waiting to see exact results for. Here is what we’re keeping an
                eye on:
              </p>
              <ul>
                <li>
                  <HSRCharacter
                    mode="inline"
                    slug="trailblazer-harmony"
                    enablePopover
                  />{' '}
                  (MoC/AS): Fugue and Trailblazer are our two Super Break
                  enablers with Trailblazer having higher numbers but Fugue
                  having her bag of mechanically transformative tricks. Some
                  characters in some modes prefer the higher numbers while
                  others prefer the tricks - we want to see where the numbers
                  lie with data before committing to placing one above the other
                  definitively.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="boothill" enablePopover />{' '}
                  (MoC): Boothill is one of the biggest benefactors of Fugue.
                  We’ve got our finger on the trigger to move him to Tier 0
                  pending next phase results,
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="rappa" enablePopover />{' '}
                  (MoC): Like Boothill, Rappa sees a huge boost from Fugue.
                  We’ve made some immediate changes to Rappa’s rankings in MoC
                  and AS but like with Boothill, we are keeping an eye on her as
                  to whether she improved enough to join T0 with Firefly and
                  Boothill,
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="himeko" enablePopover />{' '}
                  (MoC/AS/PF): Himeko is another big Fugue winner and as such
                  will also be under close watch over the next phases.
                </li>
              </ul>
              <p>
                We have also made several Apocalyptic Shadow tier list changes
                (sorry for the delay!):
              </p>
              <ul>
                <li>
                  <HSRCharacter mode="inline" slug="jiaoqiu" enablePopover /> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0.5. Jiaoqiu performance continues to improve, cementing his
                  placement in the Apex tier of Apocalyptic Shadow. He elevates
                  Acheron to another level and because of that, he deserves to
                  sit in the same tier as her,
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="moze" enablePopover /> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0.5. The FUA archetype is simply too strong in the current
                  meta and also very flexible - any of the trio of Topaz, March
                  8th or Moze can be paired with Feixiao and dominate. So it
                  doesn't make sense to keep Moze with a different rating than
                  the other two - we've raised him to Apex to fix that,
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="pela" enablePopover /> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1. The F2P champion strikes yet again - she's very flexible
                  and strong, being able to be used in multiple teams (but
                  mainly with Acheron) where her DEF Shred can help,
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="jade" enablePopover /> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1. Jade is slowly beating the allegations of being just a PF
                  bot, but that's mainly because the enemy layout has changed
                  recently in other modes, allowing her to shine. Her
                  performance in both MoC and AS has been improving and we see a
                  bright future ahead of her,
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="herta" enablePopover /> T4{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T3. Similar to Jade, the recent shift to more Blast/AoE
                  focused fights in AS improved Herta's performance. If this
                  trend continues, things may look even brighter for our
                  kurukuru,
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="serval" enablePopover /> T5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T4. Same reasoning as Jade and Herta,
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="jingliu" enablePopover />{' '}
                  T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T2. Jingliu, oh, Jingliu - what has happened to you? Sadly,
                  what was her biggest strength on her release (great self buffs
                  and ease of building), is now her biggest weakness as her base
                  low multiplier put her damage ceiling quite lower than other
                  characters. Even if you invest more into her, the damage just
                  isn't there anymore,
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="argenti" enablePopover />{' '}
                  T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T2. While the trio mentioned above started to thrive in recent
                  AS, Argenti does the opposite. Maybe the knight of beauty
                  needs some tips and help to find his place in the meta, but
                  until he does, we're lowering him slightly,
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="luka" enablePopover /> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T2. Luka is too situational and the last 3 phases simply
                  weren't good for him, causing him to underperform compared to
                  his peers in T1.5, so we're dropping him a bit,
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="asta" enablePopover /> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T2. We have a plethora of stronger supports available to us
                  nowadays, each with their own niche, and sadly, the niche Asta
                  occupies just isn't good enough anymore,
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="hanya" enablePopover /> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T3. Similar to Asta, Hanya just doesn't have a spot in the
                  current meta as nobody wants to use her,
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="sampo" enablePopover /> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T3. While the DoT duo somehow managed to survive, the same
                  can't be said about Sampo - he just struggles to much and
                  can't compete with the other Meta characters.
                </li>
              </ul>
              <h6>
                <strong>04/12/2024</strong>
              </h6>
              <p>
                <HSRCharacter mode="inline" slug="sunday" enablePopover />{' '}
                arrival to the game is more impactful than most characters as he{' '}
                <strong>
                  outperforms the other Hypercarry Amplifiers in most of their
                  top teams
                </strong>{' '}
                and also has room to gain even more meta relevance the moment
                more summoning characters are released (so 3.0). The result of
                this is a need for us to reorganize the Amplifier category to
                better display the new 'pecking order' (please no more Charmony
                Dove brainrot) into the meta, re-evaluate every character who
                can benefit from Sunday and make updates to their ranking
                accordingly. Here's what happened:
              </p>
              <ul>
                <li>
                  <strong>Amplifier Reshuffle</strong>:{' '}
                  <HSRCharacter mode="inline" slug="sparkle" enablePopover />{' '}
                  and <HSRCharacter mode="inline" slug="bronya" enablePopover />{' '}
                  are the two characters that are most impacted by the release
                  of Sunday. Both are interchangeable with him and in many cases
                  also beaten by him (more like executed) in some of their top
                  teams. With this in mind, we’ve adjusted Bronya, Sparkle and a
                  number of other Amplifier rankings according to the power
                  increase Sunday introduces.
                </li>
                <li>
                  <strong>Sunday & Damage Dealers</strong>: Sunday provides a
                  power increase to many damage dealers but one character in
                  particular gains far far more than others - Our General{' '}
                  <HSRCharacter mode="inline" slug="jing-yuan" enablePopover />.
                  Sunday isn’t just a simple damage increase for Jing Yuan -
                  he's straight up a kit overhaul for him, fixing many of his
                  flaws and increasing his performance in all 3 endgame modes
                  dramatically. We usually stick to only making additions on new
                  character releases unless they overlap with new phase data but
                  Sunday and Jing Yuan are an exception - the power of the combo
                  is undeniable and thus needs an immediate update on all three
                  lists. List of changes to Jing Yuan ratings:
                </li>
                <ul>
                  <li>
                    <strong>Memory of Chaos</strong> - T2{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    T1.
                  </li>
                  <li>
                    <strong>Pure Fiction</strong> - T2{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    T0.
                  </li>
                  <ul>
                    <li>
                      Yes, we are aware that there are too many DPS characters
                      in T0 Pure Fiction now, but that's the cost of the
                      'emergency patch'. We will make adjustments when the next
                      Pure Fiction drops as the mode is receiving a rework
                      either way, so quite a lot things will change most likely.
                    </li>
                  </ul>
                  <li>
                    <strong>Apocalyptic Shadow</strong> - T2{' '}
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      width="18"
                      className="arrow-green"
                    />{' '}
                    T0.5.
                  </li>
                </ul>
                <li>
                  <strong>Sunday Moving Forward:</strong> Sunday comes out of
                  the gates swinging - he's able to support most characters in
                  the game to some degree and even finds a place as best in slot
                  in some of their teams. With that said, Sunday definitely
                  isn’t at his maximum potential yet - he is a character that
                  specialises in advancing and buffing Summoners and right now
                  there is only really one non-supportive option Jing Yuan.
                  Based on this we’re not placing Sunday in Tier 0 at the time
                  of his release but we very well expect him to make his way
                  there soon...
                </li>
              </ul>
              <p>
                <strong>MoC Tier List update</strong>
              </p>
              <p>
                Alongside Sunday’s release we’re also updating the MoC tier list
                based on the most recent phase:
              </p>
              <ul>
                <li>
                  <HSRCharacter mode="inline" slug="lingsha" enablePopover />{' '}
                  T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0. Lingsha continues to dominate the endgame meta, offering
                  tremendous offensive power while ticking all the boxes a top
                  tier sustain needs at the same time. Because of that we're
                  raising her to T0.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="huohuo" enablePopover /> T0{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T0.5. Huohuo still serves as one of the top sustains of MoC
                  but isn’t quite performing at the level of Lingsha or
                  Aventurine. That's why we’re lowering her a tier for now based
                  on their dominance.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="dr-ratio" enablePopover />{' '}
                  T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1. The hyperinflation of HP that's happening in the last few
                  phases wasn't kind to Dr. Ratio and for the first time since
                  his release, he's showing signs of fatigue. While his single
                  target damage output is still good, he's struggling to kill
                  things fast enough and 'spreading' his chalk damage around
                  when there's multiple chunky enemies he has to deal with -
                  even with the full power of the FUA team behind his back.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="jingliu" enablePopover />{' '}
                  T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T2 and{' '}
                  <HSRCharacter mode="inline" slug="seele" enablePopover /> &{' '}
                  <HSRCharacter mode="inline" slug="qingque" enablePopover /> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T3. Each of these characters is suffering from power creep,
                  being pushed out by enemy and encounter designs not suited to
                  their kits. As a result, both their usage rates and
                  performance suffers.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="lynx" enablePopover /> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T3. Lynx isn't really played with Yunli as often as we thought
                  she would and also the increase of difficulty showcased a
                  fatal flaw in her kit - her healing just isn't enough and she
                  often gets the team killed.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="misha" enablePopover /> T4{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T5. Misha Who?
                </li>
              </ul>
              <p>
                We also added{' '}
                <HSRCharacter mode="inline" slug="rappa" enablePopover /> and{' '}
                <HSRCharacter mode="inline" slug="jing-yuan" enablePopover /> to
                the Watchlist for MoC:
              </p>
              <ul>
                <li>
                  <HSRCharacter mode="inline" slug="rappa" enablePopover /> has
                  performed significantly above her current tier list placement
                  in this phase of MoC. With that said we feel this MoC is
                  uniquely tailored towards her strengths - we want to see Rappa
                  put in a similar performance in one more phase before
                  potentially moving her into the Apex tiers.
                </li>
                <li>
                  <HSRCharacter mode="inline" slug="jing-yuan" enablePopover />{' '}
                  - Sunday has increased Jing Yuan’s power substantially - based
                  on this in MoC specifically he has jumped 2 tiers all at once.
                  But in addition to this we’re also "watchlisting" him - the
                  current MoC 12 side 1 while Lightning-weak, carries a massive
                  amount of Crowd Control. Something that makes evaluating the
                  true power of Jing Yuan and Sunday consistently challenging.
                  We want to see more from Sunday and Jing Yuan before placing
                  him any higher on the MoC tier list, so we’ll revisit him in
                  MoC with next phases data.
                </li>
              </ul>
              <h6>
                <strong>18/11/2024</strong>
              </h6>
              <p>
                In our previous Pure Fiction update we marked a number of
                characters with the potential to move up or down on the tier
                list (our watchlist). With another round of data, testing and
                community feedback we’re following through on all these changes:
              </p>
              <ul>
                <li>
                  <strong>[Jiaoqiu]</strong> T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0. Since his release, Jiaoqiu is one of the highest
                  performers in Pure Fiction and in this phase he has continued
                  his dominant performance. While previously he was mostly used
                  with Acheron (and he elevated her to new heights) in this
                  phase he's also being successfully played with Kafka/BS (in
                  DoT teams) but also with Himeko (Crit version). We believe
                  he’s earned his place in T0 because of that.
                </li>
                <li>
                  <strong>[Feixiao]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1. A Hunt character being so high in PF? What is this
                  blasphemy. No, we didn't lose our minds (at least we hope so)
                  as Feixiao is being played in this mode as an enabler for
                  Herta and Himeko - both characters rely on enemies being often
                  broken and Feixiao can easily do that, procing the kurukuru /
                  nigasanai wa super often.
                </li>
                <li>
                  <strong>[Seele]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T2. As someone wise said, balance must be kept - one Hunt unit
                  has risen in the ranks and another has to drop. Kidding aside,
                  Seele has continued to remain a niche choice outside of
                  Shatter being present in the Pure Fiction Phase. We’re
                  lowering her rating by a tier to reflect this.
                </li>
                <li>
                  <strong>[Jingliu]</strong> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T3. Jingliu continues to face challenges in Pure Fiction this
                  phase. As outlined previously we’re lowering her down a tier
                  and now she's entered the Niche category.
                </li>
              </ul>
              <p>Other Pure Fiction changes:</p>
              <ul>
                <li>
                  <strong>[Yunli]</strong> T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0. Yunli continues to surprise us. She is able to stand on
                  her own (hypercarry teams) but is even stronger when combined
                  with other synergistic damaging characters (dual DPS teams
                  with Feixiao, Himeko, and Jade) making her incredibly
                  flexible. Despite being used in a myriad of teams, Yunli
                  boasts some of the highest average scores of the mode -
                  regardless of the Phase buff she always delivers. Flexibility,
                  high performance and consistency - based on this we’re raising
                  her to Tier 0.
                </li>
                <li>
                  <strong>[Acheron]</strong> T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0. Since the release of Jiaoqiu, Acheron performance in Pure
                  Fiction drastically improved. The Foxian fixes most of her
                  issues, allowing her to reach new heights in this and other
                  modes - regardless of the Phase-specific buffs. Because of the
                  shown consistency, we're raising her to T0.
                </li>
                <li>
                  <strong>[Ruan Mei]</strong> T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0. Ruan Mei is the queen of diversity and to this day retains
                  one of the highest use rates in all modes. The release of
                  Rappa grants her one more, especially potent top tier team
                  specifically in her Break-focused niche. With a stronger
                  top-end on her teams and continued widespread use we’re
                  raising her back to Tier 0 alongside Robin. The data might not
                  always be on Ruan Mei’s side, due to her being used in such a
                  wide variety of teams but there is no doubt she’s part of
                  multiple of the strongest Pure Fiction teams right now.
                </li>
                <li>
                  <strong>[Gepard]</strong> T3{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T2. While his younger, gambling-addicted brother, is the king
                  of Shielding, Gepard has shown great performance in the last
                  few phases - those who don't have Aventurine are finding
                  success with Gepard once he's properly build. We're raising
                  him from the depths of Niche tier because of that.
                </li>
                <li>
                  <strong>[Serval]</strong> T4{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T3. Serval has seen a uptick in performance, but not big
                  enough to raise her from the Niche tier. Still, at least
                  compared to a lot characters - even those limited ones - she
                  actually sees some usage and somehow works.
                </li>
                <li>
                  <strong>[Argenti] & [Himeko]</strong> T0{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T0.5. Double whammy! Himeko and Argenti are without a doubt
                  two of the strongest Pure Fiction characters in certain
                  phases, landing them at the top of the tier list (and in our
                  data) on many occasions. But at the same time, both of them
                  have phases when they struggle and even fail to reach 30k -
                  usually phases where the enemy weaknesses do not favor their
                  element. We expect these characters to continue to be a hot
                  topic, but we will be lowering their ranking for now based on
                  their lacking performance in the last 3 phases (so what's
                  being taken into account for the purpose of the tier list).
                </li>
                <li>
                  <strong>[Bronya]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1.5. Tingyun and Pela both see frequent and competitive play
                  in Pure Fiction, popping up in a variety of team compositions
                  capable of scoring very well. We feel Bronya doesn’t quite fit
                  alongside these two in the current state of endgame in terms
                  of performance or flexibility - usually being a 2nd, 3rd or
                  even 4th pick support in most high scoring teams. For these
                  reasons we’re moving her down a tier.
                </li>
                <li>
                  <strong>[Asta] & [Hanya]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T2. Another double whammy! Asta and Hanya’s play rate and
                  performance has been in steady decline with the introduction
                  of new units and higher difficulty in Pure Fiction. This
                  combined with Bronya moving down to Tier 1.5 is the reason
                  we’ll be moving both down a tier.
                </li>
                <li>
                  <strong>[Qingque]</strong> T3{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T4. Another bad phase for our Gamba Gremlin. Her damage just
                  isn't enough anymore and we're dropping her down a tier to
                  better reflect her place in the meta.
                </li>
                <li>
                  <strong>[Lynx]</strong> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T3. With the drastic increase of difficulty in the last phase,
                  Lynx issues were exposed. She struggles to keep the team alive
                  and the additional benefits she brings aren't enough to
                  justify using her other other Sustains.
                </li>
                <li>
                  <strong>[Trailblazer Fire]</strong> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T4. We have recently moved Fire MC to Sustains (from
                  Specialist) and we're adjusting their rating to better reflect
                  their strength.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="fuse-ad-placeholder" data-fuse="22844297241"></div>
        {/* <div className="promo-top with-margin">
          <OutboundLink
            href="https://topup.bot/?game=hsr&utm_source=prydwen&utm_medium=referral&utm_campaign=january"
            target="_blank"
          >
            <StaticImage
              src="../../images/starrail/promo_tier.webp"
              alt="Toptup.bot Promotion"
              className="desktop"
            />
            <StaticImage
              src="../../images/starrail/promo_mobile.webp"
              alt="Toptup.bot Promotion"
              className="mobile"
            />
            <p className="mobile text">
              <strong>Topup.bot</strong> - Oneiric Shards at a 20% discount,
              delivered instantly to UID!
            </p>
          </OutboundLink>
        </div> */}
        <div className="tier-list-header">
          <p className="title">
            Tier List{' '}
            <span>
              (
              {(sortOption === 'moc' || sortOption === 'moc_special') &&
                'Memory of Chaos'}
              {(sortOption === 'pure' || sortOption === 'pure_special') &&
                'Pure Fiction'}
              {(sortOption === 'apo' || sortOption === 'apo_special') &&
                'Apocalyptic Shadow'}
              )
            </span>
          </p>
          <p className="sub-title">
            You're currently viewing the{' '}
            <strong>
              {(sortOption === 'moc' || sortOption === 'moc_special') &&
                'Memory of Chaos'}
              {(sortOption === 'pure' || sortOption === 'pure_special') &&
                'Pure Fiction'}
              {(sortOption === 'apo' || sortOption === 'apo_special') &&
                'Apocalyptic Shadow'}
            </strong>{' '}
            tier list. It shows{' '}
            {(sortOption === 'moc' || sortOption === 'moc_special') &&
              'how the character performs in the Memory of Chaos. Blast and single target damage are important here while AoE has niche uses against some bosses.'}
            {(sortOption === 'pure' || sortOption === 'pure_special') &&
              'how the character performs in the Pure Fiction. AoE is king here, followed by Blast while single target damage is mostly useless.'}
            {(sortOption === 'apo' || sortOption === 'apo_special') &&
              'how the character performs in the Apocalyptic Shadow. Single target and Break potential are heavily favored here.'}{' '}
            Use the switcher below to view a different tier list.
          </p>
        </div>
        <div className="eidolon-switcher">
          <div className="custom-switch">
            <Switch
              checked={eidolonSpecial}
              onChange={() => setEidolonSpecial(!eidolonSpecial)}
              onColor="#009EEC"
              offColor="#484950"
              className="switch"
            />
            Show 4★ characters at Eidolon 6 (E0 otherwise)
          </div>
        </div>
        {eidolonSpecial === true ? (
          <>
            <div className="tier-list-switcher">
              <div
                className={`option moc ${
                  (sortOption === 'moc' || sortOption === 'moc_special') &&
                  'selected'
                }`}
                onClick={() => setSortOption('moc')}
              >
                <h6>Memory of Chaos</h6>
              </div>
              <div
                className={`option pure ${
                  (sortOption === 'pure' || sortOption === 'pure_special') &&
                  'selected'
                }`}
                onClick={() => setSortOption('pure')}
              >
                <h6>Pure Fiction</h6>
              </div>
              <div
                className={`option apo ${
                  (sortOption === 'apo' || sortOption === 'apo_special') &&
                  'selected'
                }`}
                onClick={() => setSortOption('apo')}
              >
                <h6>Apocalyptic Shadow</h6>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="tier-list-switcher">
              <div
                className={`option moc ${
                  (sortOption === 'moc' || sortOption === 'moc_special') &&
                  'selected'
                }`}
                onClick={() => setSortOption('moc_special')}
              >
                <h6>Memory of Chaos</h6>
              </div>
              <div
                className={`option pure ${
                  (sortOption === 'pure' || sortOption === 'pure_special') &&
                  'selected'
                }`}
                onClick={() => setSortOption('pure_special')}
              >
                <h6>Pure Fiction</h6>
              </div>
              <div
                className={`option apo ${
                  (sortOption === 'apo' || sortOption === 'apo_special') &&
                  'selected'
                }`}
                onClick={() => setSortOption('apo_special')}
              >
                <h6>Apocalyptic Shadow</h6>
              </div>
            </div>
          </>
        )}
        {(sortOption === 'pure' || sortOption === 'pure_special') && (
          <div className="data-info-banner">
            <h4>
              Check our <span>Pure Fiction</span> Analytics for historical data!
            </h4>
            <Link to="/star-rail/pure-fiction">
              <Button variant="primary">Learn more</Button>
            </Link>
          </div>
        )}
        {(sortOption === 'apo' || sortOption === 'apo_special') && (
          <div className="data-info-banner">
            <h4>
              Check our <span>Apocalyptic Shadow</span> Analytics for historical
              data!
            </h4>
            <Link to="/star-rail/apocalyptic-shadow">
              <Button variant="primary">Learn more</Button>
            </Link>
          </div>
        )}
        {(sortOption === 'moc' || sortOption === 'moc_special') && (
          <div className="data-info-banner">
            <h4>
              Check our <span>Memory of Chaos</span> Analytics for historical
              data!
            </h4>
            <Link to="/star-rail/memory-of-chaos">
              <Button variant="primary">Learn more</Button>
            </Link>
          </div>
        )}
        <div className="employees-filter-bar hsr with-color">
          <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
        </div>
        <div className="custom-tier-list-hsr">
          <div className="custom-tier-header">
            <div className="tier-rating">
              <span>&nbsp;</span>
            </div>
            <div className="custom-tier-container">
              <div className="custom-header dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-header debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Support DPS
              </div>
              <div className="custom-header support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-header sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
            </div>
          </div>
          <CustomPopover
            enabled
            customClass="character-hover-box hsr"
            popover={
              <div className="hsr-meta-popover">
                <div>
                  <FontAwesomeIcon icon={faStar} width="30" className="apex" />
                  <FontAwesomeIcon icon={faStar} width="30" className="apex" />
                  <FontAwesomeIcon icon={faStar} width="30" className="apex" />
                </div>
                <h4 className="apex">Apex Characters</h4>
                <p>
                  Characters in this bracket are the cream of the crop. They can
                  do outrageous amount of damage, provide massive buffs (or
                  debuffs) or keep the team alive no matter what the enemy
                  throws at them. They simply can achieve the best, most
                  consistent and accessible results in their best teams,
                  allowing you to easily clear MoC and PF.
                </p>
              </div>
            }
          >
            <div className={`meta-line`}>
              <h5 className="meta-header apex">
                <FontAwesomeIcon icon={faAnglesDown} width="18" /> Apex
                characters <FontAwesomeIcon icon={faAnglesDown} width="18" />
              </h5>
            </div>
          </CustomPopover>
          <div className="custom-tier tier-0 first">
            <div className="tier-rating t-0">
              <span>T0</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '11')
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Support DPS
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '11')
                  .filter((emp) => emp.tierCategory === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '11')
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '11')
                  .filter((emp) => emp.tierCategory === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-05">
            <div className="tier-rating t-05">
              <span>T0.5</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '10')
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Support DPS
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '10')
                  .filter((emp) => emp.tierCategory === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '10')
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '10')
                  .filter((emp) => emp.tierCategory === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <CustomPopover
            enabled
            customClass="character-hover-box hsr"
            popover={
              <div className="hsr-meta-popover">
                <div>
                  <FontAwesomeIcon icon={faStar} width="30" className="meta" />
                  <FontAwesomeIcon icon={faStar} width="30" className="meta" />
                  <FontAwesomeIcon
                    icon={faStarEmpty}
                    width="30"
                    className="meta"
                  />
                </div>
                <h4 className="meta">Meta Characters</h4>
                <p>
                  Characters in this bracket make clearing the mode a breeze,
                  but compared to the Apex characters they require more work
                  from your side. Either their team options are more
                  restrictive, they require bigger Relic investment or they
                  possess a notable weakness or downside. Still, they are all
                  great and won't disappoint you if you give them what they need
                  to shine.
                </p>
              </div>
            }
          >
            <div className={`meta-line`}>
              <h5 className="meta-header meta">
                <FontAwesomeIcon icon={faAnglesDown} width="18" /> Meta
                characters <FontAwesomeIcon icon={faAnglesDown} width="18" />
              </h5>
            </div>
          </CustomPopover>
          <div className="custom-tier tier-1">
            <div className="tier-rating t-1">
              <span>T1</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9')
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Support DPS
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9')
                  .filter((emp) => emp.tierCategory === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9')
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9')
                  .filter((emp) => emp.tierCategory === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-15">
            <div className="tier-rating t-15">
              <span>T1.5</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '8')
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Support DPS
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '8')
                  .filter((emp) => emp.tierCategory === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '8')
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '8')
                  .filter((emp) => emp.tierCategory === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-2">
            <div className="tier-rating t-2">
              <span>T2</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '7')
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Support DPS
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '7')
                  .filter((emp) => emp.tierCategory === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '7')
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '7')
                  .filter((emp) => emp.tierCategory === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <CustomPopover
            enabled
            customClass="character-hover-box hsr"
            popover={
              <div className="hsr-meta-popover">
                <div>
                  <FontAwesomeIcon icon={faStar} width="30" className="niche" />
                  <FontAwesomeIcon
                    icon={faStarEmpty}
                    width="30"
                    className="niche"
                  />
                  <FontAwesomeIcon
                    icon={faStarEmpty}
                    width="30"
                    className="niche"
                  />
                </div>
                <h4 className="niche">Niche Characters</h4>
                <p>
                  Characters in this bracket are lacking in one or many fields
                  of their role in the mode. These characters still possess the
                  ability to clear all content with the right build, endgame
                  blessing, team or enemy line-up but have much lower play rates
                  and success rates than Meta and Apex characters.
                </p>
              </div>
            }
          >
            <div className={`meta-line`}>
              <h5 className="meta-header niche">
                <FontAwesomeIcon icon={faAnglesDown} width="18" /> Niche
                characters <FontAwesomeIcon icon={faAnglesDown} width="18" />
              </h5>
            </div>
          </CustomPopover>
          <div className="custom-tier tier-3">
            <div className="tier-rating t-3">
              <span>T3</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '6')
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Support DPS
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '6')
                  .filter((emp) => emp.tierCategory === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '6')
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '6')
                  .filter((emp) => emp.tierCategory === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-4">
            <div className="tier-rating t-4">
              <span>T4</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '5')
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Support DPS
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '5')
                  .filter((emp) => emp.tierCategory === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '5')
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '5')
                  .filter((emp) => emp.tierCategory === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <CustomPopover
            enabled
            customClass="character-hover-box hsr"
            popover={
              <div className="hsr-meta-popover">
                <div>
                  <FontAwesomeIcon
                    icon={faFaceSadTear}
                    width="30"
                    className="forgotten"
                  />
                </div>
                <h4 className="forgotten">The Forgotten Ones</h4>
                <p>
                  Characters in this bracket struggle. A lot. Either their kits
                  simply don't work in the mode or they require a ton of
                  investment and love to be able to work. Just avoid them unless
                  your husbando or waifu landed here and you believe in the
                  power of love.
                </p>
              </div>
            }
          >
            <div className={`meta-line`}>
              <h5 className="meta-header forgotten">
                <FontAwesomeIcon icon={faAnglesDown} width="18" /> The Forgotten
                Ones <FontAwesomeIcon icon={faAnglesDown} width="18" />
              </h5>
            </div>
          </CustomPopover>
          <div className="custom-tier tier-5">
            <div className="tier-rating t-5 te">
              <span>T5</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '4')
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Support DPS
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '4')
                  .filter((emp) => emp.tierCategory === 'Specialist')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '4')
                  .filter((emp) => emp.tierCategory === 'Amplifier')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sustain">
                <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
              </div>
              <div className="custom-tier-burst sustain">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '4')
                  .filter((emp) => emp.tierCategory === 'Sustain')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <HSRCharacter
                            slug={emp.slug}
                            mode="icon"
                            showIcon
                            enablePopover
                            showTierListchange
                            displayTierTags
                            showEidolon
                            eidolonSpecial={eidolonSpecial}
                            currentTier={sortOption}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297241"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRTierListPage;

export const Head: React.FC = () => (
  <Seo
    title="Tier List | Honkai: Star Rail | Prydwen Institute"
    description="The most popular tier list for Honkai: Star Rail that rates all available characters by their performance in Memory of Chaos, Pure Fiction and Apocalyptic Shadow. Find out who is the best!"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulHsrCharacter(
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        unitId
        slug
        name
        rarity
        element
        path
        tierCategory
        ratings {
          moc
          moc_special
          pure
          pure_special
          apo
          apo_special
        }
        tierListNew
        tierListTags
      }
    }
  }
`;
